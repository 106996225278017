import { defineComponent as _defineComponent } from 'vue'
import { setBlockTracking as _setBlockTracking, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, withModifiers as _withModifiers, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { class: "app-toast__label" }

import { AppToastType } from "@/shared/types/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppToast',
  props: {
    type: { default: "information" },
    label: {}
  },
  emits: ["remove"],
  setup(__props: any, { emit: __emit }) {



const emit = __emit;

const removeClick = () => emit("remove");

return (_ctx: any,_cache: any) => {
  return _cache[0] || (
    _setBlockTracking(-1),
    (_cache[0] = _createElementVNode("div", {
      class: _normalizeClass(`app-toast app-toast--${_ctx.type}`)
    }, [
      _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.label), 1),
      _createElementVNode("span", {
        class: "app-toast__button",
        onClick: _withModifiers(removeClick, ["stop"])
      }, "×")
    ], 2)).cacheIndex = 0,
    _setBlockTracking(1),
    _cache[0]
  )
}
}

})