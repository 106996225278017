import { defineComponent as _defineComponent } from 'vue'
import { unref as _unref, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementVNode as _createElementVNode, withCtx as _withCtx, createVNode as _createVNode, resolveComponent as _resolveComponent, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "app-empty-layout" }
const _hoisted_2 = { class: "app-empty-layout__navigation-bar" }
const _hoisted_3 = ["src"]
const _hoisted_4 = { class: "app-empty-layout__body" }
const _hoisted_5 = { class: "app-empty-layout__body__content" }

import AppButton from "@/components/app/AppButton/AppButton.vue";
import { netZeroInsightsLogoHighRes } from "@/shared/constants/icons";
import useWebCustomerStore from "@/stores/webCustomerStore/useWebCustomerStore";
import { ref } from "vue";
import AppLink from "@/components/app/AppLink/AppLink.vue";
import AppExpiryBanner from "@/components/app/AppExpiryBanner/AppExpiryBanner.vue";
import BookCallModal from "@/components/layouts/BookCallModal/BookCallModal.vue";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppEmptyLayout',
  setup(__props) {

const webCustomerStore = useWebCustomerStore();

const bookCallModal = ref(false);

const handleOpenBookCallModal = () => {
  bookCallModal.value = !bookCallModal.value;
};

return (_ctx: any,_cache: any) => {
  const _component_router_view = _resolveComponent("router-view")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_unref(webCustomerStore).showSubscriptionBanner)
      ? (_openBlock(), _createBlock(AppExpiryBanner, {
          key: 0,
          onOpenBookCallModal: handleOpenBookCallModal
        }))
      : _createCommentVNode("", true),
    _createElementVNode("header", _hoisted_2, [
      _createVNode(AppLink, {
        to: "/dashboard",
        "link-component": "router-link",
        class: "app-empty-layout__navigation-bar__brand"
      }, {
        default: _withCtx(() => [
          _createElementVNode("img", {
            src: _unref(netZeroInsightsLogoHighRes),
            alt: "Net Zero Insights",
            class: "app-empty-layout__navigation-bar__brand__logo"
          }, null, 8, _hoisted_3)
        ]),
        _: 1
      }),
      (_unref(webCustomerStore).webCustomer.preview)
        ? (_openBlock(), _createBlock(AppButton, {
            key: 0,
            href: "https://meetings-eu1.hubspot.com/federico-cristoforoni",
            label: "Upgrade",
            size: "small",
            "font-size": "12px",
            height: "30px"
          }))
        : _createCommentVNode("", true)
    ]),
    _createVNode(BookCallModal, {
      modelValue: bookCallModal.value,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((bookCallModal).value = $event))
    }, null, 8, ["modelValue"]),
    _createElementVNode("main", _hoisted_4, [
      _createElementVNode("div", _hoisted_5, [
        _createVNode(_component_router_view)
      ])
    ])
  ]))
}
}

})