import axios from "axios";
import store from "@/stores";
import router from "@/router";
import useNotificationsStore from "@/stores/notificationsStore/useNotificationsStore";

const { notify, isNotificationActive } = useNotificationsStore(store);

const instance = axios.create({
  baseURL: process.env.VUE_APP_BASE_URL,
  headers: { "Content-Type": "application/json" },
  withCredentials: true,
});

let currentNotificationId: string;
let isRedirecting = false;

instance.interceptors.response.use(
  (response) => response,
  async (error) => {
    if (
      error.message === "Network Error" &&
      !isNotificationActive(currentNotificationId)
    ) {
      currentNotificationId = notify(
        "Connection issue. Please check your internet connection.",
        "danger",
      );
    }

    const currentRoute = window.location.href.replace(
      window.location.origin,
      "",
    );

    if (
      error.response?.status === 403 &&
      !["public", "unauthorized"].includes(
        router.resolve(currentRoute)?.meta.layout as string,
      ) &&
      !isRedirecting
    ) {
      isRedirecting = true;

      sessionStorage.setItem("redirectedFrom", currentRoute);

      if (
        router.currentRoute.value.path !== "/" &&
        !isNotificationActive(currentNotificationId)
      ) {
        currentNotificationId = notify(
          "Session expired. Redirecting to login page...",
          "warning",
        );
      }

      await router.replace({ name: "SignOut" }).finally(() => {
        isRedirecting = false;
      });
    }

    return Promise.reject(error);
  },
);

export default instance;
