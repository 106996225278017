import {
  COFundingTypeSTD,
  COInvestorPartnerPrint,
  COSDG,
  COTag,
  Contact,
  PIFramework,
  TBSDG,
  TBTag,
  TBTrls,
} from "@/shared/types/filters";

import { AppToastType } from "@/shared/types/components";
import { AxiosError } from "axios";
import { FundingRoundAttachment } from "./admin";

export enum Currency {
  EUR = "EUR",
  USD = "USD",
}

export enum PatentStatus {
  GRANTED = "granted",
  PENDING = "pending",
}

export enum WebCustomerRole {
  USER = "USER",
  API = "API",
  ROLE_ADMIN = "ROLE_ADMIN",
  STARTUP = "STARTUP",
}

export interface UserTeam {
  id: number;
  name: string;
  domain?: string;
  exportCredits: number;
  startingExportCredits?: number;
}

export interface WebCustomer {
  name: string;
  surname: string;
  website: string;
  role: WebCustomerRole;
  email: string;
  enabled: boolean;
  admin: boolean;
  password: string;
  startDate: Date;
  team?: UserTeam;
  endDate: Date;
  acquisitionDate: Date;
  export: boolean;
  phone?: string;
  searchesDone: number;
  affinityAPIKey?: string;
  salesforceConnected?: boolean;
  selectedCurrency: Currency;
  pageSize: number;
  id: number;
  is2FAEnabled: boolean;
  internal: boolean;
  pending: boolean;
  preview: boolean;
  freeTrial: boolean;
  profileClaimToken?: string;
  profileClaimClientID?: number;
  freelanceStackRewardCoupon?: string;
  regexpFilterEnabled: boolean;
  newTabEnabled: boolean;
}

export interface Startup {
  id: number;
  visible: number;
  clientID: number;
  name: string;
  lastReviewer?: string;
  logo?: string;
  dealsLastReviewer?: string;
  dealsReviewDate?: string;
  website: string;
  domain?: string;
  pitchLine?: string;
  description?: string;
  fundingAmount?: number;
  fundingString?: string;
  fundingAmountUSD?: number;
  fundingStringUSD?: string;
  fundingRangeID?: number;
  fundingRange?: string;
  lastRoundDate?: string;
  aggregationDate?: string;
  acquisitionDate?: string;
  sustainabilityMetric?: number;
  sustainabilityMetricID?: number;
  sustainabilityMetricLabel?: string;
  foundedDate?: number;
  countryID?: number;
  country?: string;
  city?: string;
  currentEmployeesCount?: number;
  email?: string;
  phone?: string;
  sizeID?: number;
  size?: string;
  stageID?: number;
  stage?: string;
  trl?: TBTrls;
  linkedinURL?: string;
  twitterURL?: string;
  facebookURL?: string;
  directURL?: string;
  revenueEuro?: number;
  revenueYear?: number;
  lastSeenDate?: string;
  currentlyFundraising?: boolean;
  currentlyFundraisingDate?: string;
  pitchdeckURL?: string;
  lastPostMoneyValuation?: string;
  lastRoundAmount?: number;
  lastRoundAmountUSD?: number;
  lastRoundAmountString?: string;
  lastRoundAmountStringUSD?: string;
  lastRoundType?: string;
  revenuesRange?: string;
  tags?: TBTag[];
  tagSet?: COTag[];
  fundingTypes?: string[];
  fundingTypeSet?: COFundingTypeSTD[];
  sdgs?: TBSDG[];
  sdgSet?: COSDG[];
  investorPartnerSet?: COInvestorPartnerPrint[];
  note?: string;
  platformOrder: number;
  companyContactSet?: Contact[];
  isFundRaising?: boolean;
  roundCount?: number;
  piFrameworks: PIFramework[];
  roundWithDateCount?: number;
  fundingRangeUSD?: string;
  fundingRangeIDUSD?: number;
  reviewDate?: string | number;
  eutopiaScore?: number;
  fundRaising?: number | string;
  admin4?: string;
  continent?: string;
  legalNames?: string[];
  alternativeNames?: string[];
  numberOfEquityRounds?: number;
  numberOfDebtRounds?: number;
  numberOfGrants?: number;
  impact?: string;
  uniqueSellingProposition?: string;
  intellectualProperty?: boolean;
  employeesGrowthJSON?: string;
  yoYCorrespondingQuarter?: string;
  yoYEmployeesGrowth?: number;
  qoQCorrespondingQuarter?: string;
  qoQEmployeesGrowth?: number;
}

export interface COFundingRoundsPrint {
  id: number;
  clientId: number;
  roundDate?: string;
  roundType?: string;
  roundCurrency?: string;
  roundAmount?: number;
  roundAmountUSD?: number;
  roundAmountID: number;
  coFundingRoundID: number;
  originalRoundAmount?: number;
  equityStageID: number;
  exitStageID: number;
  fundingRange?: string;
  financingType?: string;
  roundNewsIDs?: number[];
  roundInvestorIDs?: number[];
  roundNews?: FundingRoundNews[];
  roundInvestors?: FundingRoundInvestor[];
  valuationAmount?: number;
  valuationCurrency?: string;
  hideValuation?: boolean;
  source?: string;
  status?: string;
  raisedToDate: string;
  attachments?: FundingRoundAttachment[];
}

export interface FundingRound {
  acquisitionDate?: string;
  clientCountryCode: string;
  clientFoundedDate: string;
  clientHQ: string;
  clientId: number;
  clientLogoURL: string;
  clientName: string;
  clientPitchLine: string;
  currentEmployeesCount?: number;
  financingType: string;
  id: number;
  lastRound: true;
  numberOfRounds: number;
  originalRoundAmount: number;
  roundAmount: number;
  roundAmountRangeID: number;
  roundAmountRangeIDUSD: number;
  roundAmountString: string;
  roundAmountStringUSD: string;
  roundAmountUSD: number;
  roundCurrency: Currency;
  roundDate?: string;
  roundNumber: number;
  roundType: string;
  syncDate?: string;
  totalFunding: number;
  totalFundingRangeID?: string;
  totalFundingRangeIDUSD?: string;
  totalFundingString: string;
  totalFundingStringUSD: string;
  totalFundingUSD: number;
  roundNewsPOJOs?: FundingRoundNews[];
  size?: string;
  sizeID?: number;
  roundInvestorPOJOs?: FundingRoundInvestor[];
  lastSeenDate?: string;
  exitStageID?: number;
  valuationAmount?: number;
  trl?: string;
  trlID?: number;
}

export interface FundingRoundNews {
  acquisitionDate?: string;
  clientId?: string;
  fundingRoundId: number;
  googleTitle: string;
  hotSentence?: string;
  id: number;
  newsDate?: string;
  parameters?: string;
  url: string;
}

export interface FundingRoundInvestor {
  fundingRoundId: number;
  id: number;
  logoURL?: string;
  name: string;
  website: string;
  investorTypeLabel?: string;
  investorSince?: string;
}

export interface Patent {
  id: number;
  clientID: number;
  familyID?: number;
  filingDate?: string;
  grantDate?: string;
  googlePatentsUrl?: string;
  jurisdiction?: number;
  jurisdictionName?: string;
  patentAbstract?: string;
  publicationDate?: string;
  publicationNumber: string;
  status: PatentStatus;
  title?: string;
}

export interface PatentOption {
  label: null | Patent["jurisdictionName"];
}

export type PitchLineOption = [number, string];

export interface TBValueChain {
  id: number;
  tagId: number;
  valueChain: string;
  editedDate: Date;
}

export interface WCColumnType {
  id: number;
  type: string;
}

export interface WCColumn {
  id: number;
  name: string;
  webCustomerId: number;
  editedDate: Date;
  typeId: number;
  columnType: WCColumnType;
}

export interface WCColumnMapping {
  id: number;
  columnId: number;
  clientId: number;
  value: string;
  wcColumn: WCColumn;
}

export interface CustomTag {
  id: number;
  label: string;
  isPlatform: number;
  isCustomer: number;
  shouldUpdate: number;
}

export interface SalesforceAuthDetails {
  consumerKey: string;
  consumerSecret: string;
  instanceUrl: string;
}

export type FreeTrialMessage =
  | "SEARCH_LIMIT_EXCEEDED"
  | "DETAILS_LIMIT_EXCEEDED"
  | "PAGE_CHANGE"
  | "DOWNLOAD"
  | "MANUAL_CLICK"
  | "SEE_MORE_RESULTS"
  | "FUNDING_ROUNDS_CHANGE"
  | "CONTACT"
  | "PATENT_OFFICE_CHANGE"
  | "EXPORT_AFFINITY"
  | "EXPORT_SALESFORCE"
  | "TEAM_EXPORT_CREDIT"
  | "DEFAULT";

export interface Notification {
  id: string;
  label: string;
  type?: AppToastType;
  onClick: () => void;
}

export interface TooltipData {
  label: string;
  value: string | number;
}

export interface AppError {
  exceptionName: string;
  timeStamp: Date;
  message: string;
}

export type AppAxiosError = AxiosError<AppError>;
