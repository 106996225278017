import { useCssVars as _useCssVars, defineComponent as _defineComponent } from 'vue'
import { unref as _unref, renderSlot as _renderSlot, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, normalizeClass as _normalizeClass, Fragment as _Fragment, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, resolveDynamicComponent as _resolveDynamicComponent, mergeProps as _mergeProps, withCtx as _withCtx } from "vue"

import { ref, computed, defineAsyncComponent } from "vue";
import AppIcon from "@/components/app/AppIcon/AppIcon.vue";
import AppLoader from "@/components/app/AppLoader/AppLoader.vue";
import BaseIcon from "@/components/app/BaseIcon/BaseIcon.vue";
import { AppButtonType } from "@/shared/types/components";
import { AppLoaderSize } from "@/shared/types/components";


export default /*@__PURE__*/_defineComponent({
  __name: 'AppButton',
  props: {
    iconSize: { default: "16px" },
    trailingIconSize: { default: "16px" },
    padding: { default: "8px 20px" },
    fontSize: { default: "14px" },
    fontWeight: { default: "300" },
    width: { default: "fit-content" },
    height: {},
    icon: {},
    iconColor: {},
    label: {},
    color: {},
    iconHover: {},
    isLoading: { type: Boolean },
    isDisabled: { type: Boolean },
    type: { default: "primary" },
    nativeType: { default: "button" },
    trailingIcon: {},
    trailingIconHover: {},
    shiftTrailingIconOnHover: { type: Boolean },
    to: {},
    href: {},
    targetBlank: { type: Boolean },
    labelClass: {},
    iconClass: {},
    loadingText: { default: "Loading..." }
  },
  setup(__props: any) {

_useCssVars(_ctx => ({
  "453a48c7": (_ctx.width),
  "483fd646": (_ctx.height),
  "5fd19ad2": (_ctx.padding),
  "fbd5cb62": (_ctx.fontSize),
  "57cb2146": (_ctx.fontWeight)
}))

const props = __props;

const isHovered = ref(false);

const isIconOnly = props.icon && !props.label && !props.trailingIcon;

const iconColorStyle = { color: props.iconColor };

const checkIsLoadingTextBtn = computed(() => props.isLoading && !props.icon);

const buttonLabel = computed(() =>
  checkIsLoadingTextBtn.value ? props.loadingText : props.label,
);

const isLink = Boolean(props.to || props.href);

const buttonComponent = computed(() => {
  if (!isLink) {
    return {
      component: "button",
      bind: {},
    };
  }

  return {
    component: defineAsyncComponent(
      () => import("@/components/app/AppLink/AppLink.vue"),
    ),
    bind: {
      href: props.href,
      to: props.to,
      linkComponent: props.to ? "router-link" : "a",
    },
  };
});

//TODO: remove this after completing update everywhere
const iconSrc = computed(() =>
  isHovered.value && !props.isDisabled && props.iconHover
    ? props.iconHover
    : props.icon,
);

const trailingIconSrc = computed(() =>
  isHovered.value && !props.isDisabled && props.trailingIconHover
    ? props.trailingIconHover
    : props.trailingIcon,
);

const isIconImported = (icon: string) =>
  icon?.startsWith("data:image/png") ||
  icon?.startsWith("/img/") ||
  icon?.includes("http");

const setIsHover = (value: boolean) => {
  isHovered.value = value;
};

return (_ctx: any,_cache: any) => {
  return (_openBlock(), _createBlock(_resolveDynamicComponent(buttonComponent.value.component), _mergeProps({
    disabled: _ctx.isDisabled || _ctx.isLoading || checkIsLoadingTextBtn.value,
    type: _ctx.nativeType,
    class: [
      'app-button',
      `app-button--${checkIsLoadingTextBtn.value || _ctx.isDisabled ? 'disabled' : _ctx.type}`,
      { 'app-button--icon-only': _unref(isIconOnly) },
    ]
  }, { ..._ctx.$attrs, ...buttonComponent.value.bind }, {
    style: { color: _ctx.color },
    onMouseover: _cache[0] || (_cache[0] = ($event: any) => (setIsHover(true))),
    onMouseout: _cache[1] || (_cache[1] = ($event: any) => (setIsHover(false)))
  }), {
    default: _withCtx(() => [
      _renderSlot(_ctx.$slots, "left-icon"),
      _renderSlot(_ctx.$slots, "default", {}, () => [
        (_ctx.icon)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 0 }, [
              (_ctx.isLoading && _ctx.icon)
                ? (_openBlock(), _createBlock(AppLoader, {
                    key: 0,
                    size: _ctx.iconSize || _unref(AppLoaderSize).SM
                  }, null, 8, ["size"]))
                : (isIconImported(_ctx.icon))
                  ? (_openBlock(), _createBlock(AppIcon, {
                      key: 1,
                      src: iconSrc.value,
                      size: _ctx.iconSize,
                      class: _normalizeClass(['app-button__icon', _ctx.iconClass])
                    }, null, 8, ["src", "size", "class"]))
                  : (_openBlock(), _createBlock(BaseIcon, {
                      key: 2,
                      icon: _ctx.icon,
                      class: _normalizeClass(['app-button__icon', _ctx.iconClass]),
                      size: _ctx.iconSize,
                      style: iconColorStyle
                    }, null, 8, ["icon", "class", "size"]))
            ], 64))
          : _createCommentVNode("", true),
        (buttonLabel.value)
          ? (_openBlock(), _createElementBlock("span", {
              key: 1,
              class: _normalizeClass(["app-button__label", _ctx.labelClass])
            }, _toDisplayString(buttonLabel.value), 3))
          : _createCommentVNode("", true),
        (_ctx.trailingIcon)
          ? (_openBlock(), _createElementBlock(_Fragment, { key: 2 }, [
              (isIconImported(_ctx.trailingIcon))
                ? (_openBlock(), _createBlock(AppIcon, {
                    key: 0,
                    src: trailingIconSrc.value,
                    size: _ctx.trailingIconSize,
                    class: _normalizeClass([
            'app-button__icon',
            _ctx.iconClass,
            {
              'app-button__trailing-icon':
                _ctx.shiftTrailingIconOnHover && isHovered.value,
            },
          ])
                  }, null, 8, ["src", "size", "class"]))
                : (_openBlock(), _createBlock(BaseIcon, {
                    key: 1,
                    icon: _ctx.trailingIcon,
                    class: _normalizeClass(['app-button__icon', _ctx.iconClass]),
                    size: _ctx.trailingIconSize
                  }, null, 8, ["icon", "class", "size"]))
            ], 64))
          : _createCommentVNode("", true)
      ])
    ]),
    _: 3
  }, 16, ["disabled", "type", "class", "style"]))
}
}

})